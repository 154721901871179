import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { LayoutCheckView, PriceInputView, CitiesCheckView, AreasCheckView, SchoolsCheckView, } from "./form/searchFormView";

export const BaibaisSearchView = ({setOnSearch, setSearchArr, searchArr, searchSubmit}) => {

    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues:{
            underPrice: searchArr.underPrice,
            upperPrice: searchArr.upperPrice
        }
    });

    //Layout - 間取り
    const [checkLayouts, setCheckLayouts] = useState(searchArr.layouts)
    const [checkCities, setCheckCities] =  useState(searchArr.cities)
    const [checkAreas, setCheckAreas] =  useState(searchArr.areas)
    const [checkSchools, setCheckSchools] =  useState(searchArr.schools)

    const onSubmit = (currentInput) => {

        setSearchArr({
            search : true,
            layouts : checkLayouts,
            upperPrice :currentInput.upperPrice,
            underPrice :currentInput.underPrice,
            cities : checkCities,
            areas: checkAreas,
            schools: checkSchools
        })

        const layoutQuery = `layouts=${checkLayouts.join(',')}`
        const priceQuery = `upper_price=${currentInput.upperPrice}&underPrice==${currentInput.underPrice}`
        const cityQuery = `cities=${checkCities.join(',')}`
        const areaQuery = `areas=${checkAreas.join(',')}`
        const schoolsQuery = `schools=${checkSchools.join(',')}`
        
        const requestQuery = `${layoutQuery}&${priceQuery}&${cityQuery}&${areaQuery}&${schoolsQuery}`

        searchSubmit(requestQuery)
    }


   return (
        <section className="searchFrom">
            <div className="searchFrom-inner">
                <h1> 物件検索　</h1>
                <button className="btn" onClick={() => { setOnSearch(0) }}>閉じる</button>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <LayoutCheckView {...{
                        type:'baibais',
                        setCheckLayouts, 
                        checkLayouts}}></LayoutCheckView>
                    <PriceInputView {...{register,unit:'万円'}}></PriceInputView>
                    <CitiesCheckView {...{
                        type:'baibais',
                        checkCities, 
                        setCheckCities}}></CitiesCheckView>
                    <AreasCheckView {...{
                        type:'baibais',
                        checkCities, 
                        checkAreas, 
                        setCheckAreas}}></AreasCheckView>
                    <SchoolsCheckView {...{
                        type:'baibais',
                        checkCities,
                        checkAreas,
                        checkSchools,
                        setCheckSchools,
                    }}></SchoolsCheckView>
                    <div className="searchFrom-submit-wrap">
                        <button type="submit">検索</button>
                    </div>
                </form>
              

            </div>
        </section>
    )
}
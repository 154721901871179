import React from "react";
import { Link } from "react-router-dom";
import { addComma } from 'src/utils/textUtils';
import parse from 'html-react-parser';

export const BaibaisIndexView = (props) => {

    const {respons, data:baibais } = props.data.getOrThrow();
    
   // console.log(baibais)
    return (
        <>
         <ul className="bukken-items-chintai">
            {       
                
                baibais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                                : src = `${process.env.REACT_APP_DIR}/images/noimg.png`

                    return (
                        <li key={i}>
                            <Link to={`/baibaisDetail/${c.id}`} target="_blank">
                                <div className="osusume-item-img">
                                    <figure><img src={src} /></figure>
                                    <p className="layout">{c.room_layout}</p>
                                    <p className="price">価格 <strong>{parse(addComma(c.price))}</strong></p>
                                </div>
                                <div className="osusume-item-info">
                                    <p className="osusume-item-area">
                                        <span>{c.search_params.category}</span>
                                        {c.search_params.city} / {c.search_params.area}
                                    </p>
                                    <h3 className="osusume-item-title">{c.address}</h3>
                                </div>
                            </Link>
                        </li>
                    )
                })
            }
                
            </ul>
        </>
    )
}
import React from "react";
import { textToBr } from 'src/utils/textUtils';
import parse from 'html-react-parser';
import { formattedYearMonth } from 'src/utils/dateTime';

export const ChintaiColumnParts = ({detail}) => {

    const colums = [
        {key: 'management_expenses', name: '管理費'},
        {key: 'common_service_fee', name: '共益費'},
        {key: 'security_deposit', name: '敷金'},
        {key: 'key_money', name: '礼金'},
        {key: 'deposit', name: '保証金'},
        {key: 'address', name: '所在地'},
        {key: 'room_layout', name: '間取り'},
        {key: 'proprietary_area', name: '専有面積'},
        {key: 'floor', name: '階'},
        {key: 'direction', name: '向き'},
        {key: 'building_type', name: '建物種別'},
        {key: 'room_layout', name: '間取り'},
        {key: 'structure', name: '構造'},
        {key: 'built_year_month', name: '築年月'},
        {key: 'property_insurance', name: '保険'},
        {key: 'parking', name: '駐車場'},
        {key: 'move_in', name: '入居時期'},
        {key: 'trading_aspect', name: '取引態様'},
        {key: 'total_of_houses', name: '総戸数'},
        {key: 'summary', name: '部屋の特徴・設備'},
        {key: 'traffic', name: '路線・バス'},
        {key: 'surroundings', name: '周辺環境'},
        {key: 'school', name: '学校区'},
        {key: 'school_traffic', name: '学校までの距離'},
        {key: 'brokerage', name: '仲介料'},
        {key: 'equipment', name: '設備'},
    ]

    return (
        <>
            <dl className="col1">
                <dt>物件名</dt>
                <dd>{detail.name}</dd>
            </dl>
            <dl className="col2">
                { 
                colums.map((c, i) => {
                    if(detail[c.key] && (detail[c.key] != 'undefined' && detail[c.key] != 'null')){
                        return (
                            <>
                                <dt>{c.name}</dt>
                                <dd>
                                    {
                                        c.key === 'built_year_month' ? formattedYearMonth(detail[c.key]) : 
                                                    (typeof detail[c.key] === 'string' ? parse(textToBr(detail[c.key])) : detail[c.key])
                                        
                                    }
                                </dd>
                            </>
                        )
                    }else{}
                })
                }
            </dl>
        </>
    )
}
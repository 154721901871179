import React from "react";
import { Link } from "react-router-dom";

export const ContentsTemplate = ({children, contentsCss}) => {
    
    // CSSファイルのURLを指定する
    /*
    if(contentsCss){
        const cssUrl = `${process.env.REACT_APP_DIR}css/${contentsCss}.css`;
       // console.log(cssUrl)
        // link要素を作成する
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = cssUrl;
         // ページのhead要素にlink要素を追加する
        document.head.appendChild(link);
    }else{}
    */

    let headerNavbtnOn = true
    const headerNavBtn =  document.getElementsByClassName('header-nav-btn');
    const headerNav =  document.getElementsByClassName('header-nav');

    const headerNavClick = () => {
        
        headerNavbtnOn ? headerNavBtn[0].classList.add("active") :  headerNavBtn[0].classList.remove("active");
        headerNavbtnOn ? headerNav[0].classList.add("active") :  headerNav[0].classList.remove("active");
        headerNavbtnOn = !headerNavbtnOn;
    }
    const removeActive = () => {
        headerNavbtnOn ? headerNavBtn[0].classList.add("active") :  headerNavBtn[0].classList.remove("active");
        headerNavbtnOn ? headerNav[0].classList.add("active") :  headerNav[0].classList.remove("active");
        headerNavbtnOn = !headerNavbtnOn;
    } 

    return (
        <>  
            <header>
                <div className="header-inner">
                    <h1 className="header-logo"><Link to={`/`}><img src={`${process.env.REACT_APP_DIR}images/logo.png`}/></Link></h1>
                    <nav className="header-nav" onClick={() => {removeActive()}}>
                        <ul>
                            <li><Link to={`/`}>TOP</Link></li>
                            <li><Link to={`/chintais`}>賃貸を探す</Link></li>
                            <li><Link to={`/baibais`}>新築・中古物件を探す</Link></li>
                            <li><Link to={`/lands`}>土地を探す</Link></li>
                            <li><Link to={`/company`}>会社概要</Link></li>
                            <li><Link to={`/contact`}>お問い合せ</Link></li>
                        </ul>
                    </nav>
                    <div className="header-nav-btn" onClick={() => {headerNavClick()}}><p>menu</p><span></span><span></span><span></span></div>

                </div>
            </header>
            {children}
        </>
    )

}
import React,{useState} from "react";
import { Link } from "react-router-dom";
import { SlideGallaryParts } from "../Parts/slideGallary/slideGallaryParts";
import parse from 'html-react-parser';
import { textToBr, addComma } from 'src/utils/textUtils';
import { LandColumnParts } from "../Parts/landColumnsParts";
import { BukkenFormView } from "./form/bukkenFormView";
import { ConfirmView } from "./form/confirmView";

export const LandsDetailView = (props) => {
    
    const {respons, data: detail} = props.data.getOrThrow()

    document.title = `${detail.name} - ${detail.address} | 土地物件  | 有限会社東和ハウジング 宮崎県日向市`

    const [input, setInput] = useState(0)
    const [onConfrim, setOnConfrim] = useState(0)

    return (
        <>
        <div className="breadcrumb-inner">    
            <nav className="breadcrumb">
                <ul>
                    <li><Link to={'/'}>Home</Link></li>
                    <li><Link to={'/lands'}>土地一覧</Link></li>
                    <li>{detail.name}</li>
                </ul>
            </nav>
        </div>
        {onConfrim === 0 &&
        <>
        <section className="bukkenview-chintai">
            <div className="bukkenview-inner">
                <h1 className="bukkenview-title">{detail.name}</h1>

                <div className="bukkenview-topics">
                    <div className="item-layout tubo">
                        <dl>
                            <dt>坪数 / 面積（㎡）</dt>
                            <dd>{detail.tubo} / {detail.land_area}</dd>
                        </dl>
                    </div>
                    <div className="item-price">
                        <h4></h4>
                        <dl>
                            <dt>価格</dt>
                            <dd>{ parse(addComma(detail.price))}  </dd>
                        </dl>
                    </div>
                    <div className="item-other">
                        <dl>
                            <dt>小学校区</dt>
                            <dd>{detail.search_params.jhs} </dd>
                        </dl>
                        <dl>
                            <dt>所在地</dt>
                            <dd>{detail.address}</dd>
                        </dl>
                        <dl>
                            <dt>周辺環境</dt>
                            <dd>{detail.surroundings}</dd>
                        </dl>
                    </div>
                </div>

                <div className="bukkenview-images">
                    { detail.images.length > 0 &&
                    <div className="item-photos">
                        <SlideGallaryParts {...{images: detail.images}}></SlideGallaryParts>
                    </div>
                    }
                </div>
            
                <h3 className="osusume-point">物件概要</h3>
                <p className="osusume-summary">
                    {parse(textToBr(detail.summary))}
                </p>

                {detail.gmap && 
                    <>
                    <h3 className="osusume-point">所在地地図</h3>
                    <div className="gmap">
                        {parse(detail.gmap)}
                    </div>
                    </>
                }

                 <div className="bukkenview-info">
                    <LandColumnParts {...{detail}}></LandColumnParts>
                </div>
            </div>
        </section>

         <BukkenFormView {...{setOnConfrim, 
                setInput, 
                dir:'landsDetail', 
                address:detail.address,
                id:detail.id, 
                name:detail.name}}>    
            </BukkenFormView>
            </>
        }

        {onConfrim === 1 &&
            <section className="contact">
            <div className="contact-inner">
                <h1 className="titleBar01">お問い合せフォーム</h1>
                <ConfirmView {...{input, setOnConfrim, type:'bukken'}}></ConfirmView>
            </div>
            </section>
        }
        </>
    )
}
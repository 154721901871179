import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchBaibaiIndex } from "src/data/fetchBaibai";
import { Link } from "react-router-dom";
const BukkenList = (props) => {
    const {respons, data:baibais } = props.data.getOrThrow();
        
    let newBaibais;
    respons === 'success' ? newBaibais = baibais.slice(0, 4) :  newBaibais = []

    
    return (
        <>
            {
                newBaibais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                    <li key={i}>
                        <Link to={`/baibaisDetail/${c.id}`}>
                            <div className="topicks-item-img">
                                <img src={src}/>
                            </div> 
                            <div className="topicks-item-info">
                                <p>{c.name}</p>
                                <p>価格：{c.price}</p>
                                <p>住所：{c.address}</p>
                            </div> 
                        </Link>
                    </li>
                    )
                })
            }
        </>
    )
}

export const NewBaibaisView = () => {

    const [baibais, setBaibais] = useState(0)

    useEffect(() => {
        setBaibais(new Useable(fetchBaibaiIndex('')))
    },[])
    
    return (
        <>
         <ul className="topicks-list">
            { baibais !== 0 &&
                <Suspense>
                    <BukkenList {...{data:baibais}}></BukkenList>
                </Suspense>
                }
        </ul>
        </>
    )
}
import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { IndexPage } from './components/Pages/indexPage'
import { ChintaisPage } from './components/Pages/chintaisPage'
import { ChintaisDetailPage } from './components/Pages/chintaisDetailPage'
import { LandsPage } from './components/Pages/landsPage'
import { LandsDetailPage } from './components/Pages/landsDetailPage'
import { BaibaisPage } from './components/Pages/baibaisPage'
import { BaibaisDetailPage } from './components/Pages/baibaisDetailPage'
import { ContentsTemplate } from "./components/templates/contetnsTemplate";
import { TopTemplate } from "./components/templates/topTemplate";
import { CompanyPage } from "./components/Pages/companyPage";
import { ContacPage } from "./components/Pages/contactPage";
import { NoContents } from "./components/Pages/404Page";

function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_DIR}>  
      <Routes>

        <Route path={`/`} element={
           <TopTemplate {...{contentsCss:0}}><IndexPage></IndexPage></TopTemplate>
        } />
        
        <Route path={`/chintais`} element={
          <ContentsTemplate {...{contentsCss:'contents'}}><ChintaisPage /></ContentsTemplate>
        } />
        <Route path={`/chintaisDetail/:id`} element={
           <ContentsTemplate {...{contentsCss:'contents'}}><ChintaisDetailPage /></ContentsTemplate>
        } />
      
        <Route path={`/lands`} element={
          <ContentsTemplate {...{contentsCss:'contents'}}><LandsPage /></ContentsTemplate>
        } />
        <Route path={`/landsDetail/:id`} element={
           <ContentsTemplate {...{contentsCss:'contents'}}><LandsDetailPage /></ContentsTemplate>
        } />
        
        <Route path={`/baibais`} element={
           <ContentsTemplate {...{contentsCss:'contents'}}><BaibaisPage /></ContentsTemplate>
        } />
        <Route path={`/baibaisDetail/:id`} element={
           <ContentsTemplate {...{contentsCss:'contents'}}><BaibaisDetailPage /></ContentsTemplate>
        } />
         <Route path={`/company`} element={
           <ContentsTemplate {...{contentsCss:'contents'}}><CompanyPage /></ContentsTemplate>
        } />
         <Route path={`/contact`} element={
           <ContentsTemplate {...{contentsCss:'contact'}}><ContacPage /></ContentsTemplate>
        } />
          <Route path={`/404`} element={
            <ContentsTemplate {...{contentsCss:0}}><NoContents></NoContents></ContentsTemplate>
        } />

 
      </Routes>
    </BrowserRouter>
  );
}

export default App;

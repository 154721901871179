import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchBaibaiInfo } from "src/data/fetchBaibai";
import { Loader } from "src/components/Parts/loaderParts";
import { BaibaisDetailView } from "../Views/baibaisDetailView";
import { useParams } from 'react-router-dom';

export const BaibaisDetailPage = () => {
    document.body.id = "contents";
    const { id } = useParams()

    const [detail, setDetail] = useState(0)


    useEffect(() => {
        setDetail(new Useable(fetchBaibaiInfo(id)))
    },[])

    return (
        <>
        {detail && 
        <Suspense fallback={<Loader></Loader>}>
            <BaibaisDetailView {...{data:detail}}></BaibaisDetailView>
        </Suspense>
        }
        </>
    )
}
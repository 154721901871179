import React from "react";

export const SearchConditionParts = ({searchArr}) => {

    let layout;
    searchArr.layouts ? layout = searchArr.layouts.map((i) => {
        return `${i} /`
    }) : layout = ''

    let city;
    searchArr.cities.length > 0 ? city = searchArr.cities.map((i) => {
        return `${i} /`
    }) : city = ''

    let area;
    searchArr.areas.length > 0 ? area = searchArr.areas.map((i) => {
        return `${i} /`
    }) : area = ''

    let school;
    searchArr.schools.length > 0 ? school = searchArr.schools.map((i) => {
        return `${i} /`
    }) : school = ''

  
 
    return(
        <div className="search_condeition">
            <span>検索条件</span>
           {layout != '' && 
                <> <b>間取り：</b>{layout}　</>
           }
           {city != '' && 
                <> <b>市町村：</b>{city}　</>
           }
           {area != '' && 
                <><b> 地区：</b>{area}　</>
           }
           {school != '' && 
                <><b> 学校区：</b>{school}　</>
           }
        </div>
    )
}
import React from "react";
import { Link } from "react-router-dom";
import { addComma } from 'src/utils/textUtils';
import parse from 'html-react-parser';



export const LandsIndexView = (props) => {

    const {respons, data:lands } = props.data.getOrThrow();
    
    
    return (
        <>
            <ul className="bukken-items-land">
                {
                    lands.map((c, i) => {
                        let src;
                        c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                                        : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                                        
                        return (
                            <>
                                <li>
                                    <Link to={`/landsDetail/${c.id}`} target="_blank">
                                        <div className="osusume-item-img">
                                            <figure><img src={src} /></figure>
                                            <p className="layout">{c.tubo}</p>
                                            <p className="price">価格 <strong>{parse(addComma(c.price))}</strong></p>
                                        </div>
                                        <div className="osusume-item-info">
                                            <p className="osusume-item-area">
                                                <span>土地</span>
                                                {c.search_params.city} / {c.search_params.area}
                                            </p>
                                            <h3 className="osusume-item-title">{c.address}</h3>
                                        </div>
                                    </Link>
                                </li>
                            </>
                        )
                    })
                    
                }   
            </ul>
        </>
    )
}
import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchChintaiIndex } from "src/data/fetchChintai";
import { Loader } from "src/components/Parts/loaderParts";
import { ChintaisIndexView } from "../Views/chintaisIndexView";
import { ChintaisSearchView } from "../Views/chintaisSearchView"
import { SearchConditionParts } from "../Parts/searchConditionParts";

export const ChintaisPage = () => {
    document.title = '賃貸物件 | 有限会社東和ハウジング 宮崎県日向市'
    const [chintais, setChintais] = useState(0)
    const [ onSearch, setOnSearch ] = useState(0)
    const [ searchQuery, setSearchQuery ] = useState('')

    const [searchArr, setSearchArr ] = useState({
        search: false,
        layouts:[],
        upperPrice:'',
        lowerPrice:'',
        cities:[],
        areas:[],
        schools:[]
    })

    useEffect(() => {
        setChintais(new Useable(fetchChintaiIndex(searchQuery)))
    },[searchQuery])
    
    const searchSubmit = (requestQuery) => {
        window.scrollTo(0,0)
        setOnSearch(0)
        setSearchQuery(requestQuery) 
    }


    return (
        <>
        {
        onSearch === 0 &&
        <section className="bukken">
            <div className="bukken-inner">
                <div className="pagetitle-chintai"><h1><span>賃貸物件</span></h1></div>
                <button className="btn" onClick={() => { setOnSearch(1) }}>物件・条件検索</button>
               {
                    <SearchConditionParts {...{searchArr}}></SearchConditionParts>
               }

                {chintais && 
                <Suspense fallback={<Loader></Loader>}>
                    <ChintaisIndexView {...{data:chintais}}></ChintaisIndexView>
                </Suspense>
                }
            </div>
        </section>
        }
        {
            onSearch === 1 &&
            <ChintaisSearchView {...{
                setOnSearch, 
                searchSubmit, 
                setSearchArr,
                searchArr}}></ChintaisSearchView>
        }
        </>
    )
}
import { Text } from "html-react-parser";

export const textToLink = (text: string) => {
    // 正規表現でURLを抽出
    const regexp_url = /(https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+)/g;
    let linkedComment = text.replace(regexp_url, '<a href="$1">$1</a>');
  
    // 正規表現で#を抽出
    const regexp_hash = /#+([a-zA-Z0-9亜-熙ぁ-んァ-ヶー-龥朗-鶴.\-_]+)/g;
    linkedComment = linkedComment.replace(
      regexp_hash,
      '<a href="/search?q=$1&type=hash">#$1</a>'
    );
  
    // 正規表現で@を抽出
    const regexp_at = /@+([a-zA-Z0-9亜-熙ぁ-んァ-ヶー-龥朗-鶴.\-_]+)/g;
    linkedComment = linkedComment.replace(
      regexp_at,
      '<a href="/search?q=$1&type=at">@$1</a>'
    );

    return linkedComment;
  };

export const textToBr = (text: string) => {

  return text.replace(/(\r\n|\r|\n)/g, '<br>');
  
}

export function numFormat(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function addComma(num) {
  if (typeof num !== 'string') {
    num = num.toString();
  }
  
  // 全角数字を半角数字に変換
  num = num.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 65248);
  });
  
  // カンマの挿入
  num = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  
  //与えられた文字列の中でスラッシュ（/）があれば、スラッシュの後に改行を入れる
  const regex = /\/+/g;
  
  return num.replace(regex, match => ` ${match}<br/>`);
}
import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchBaibaiIndex } from "src/data/fetchBaibai";
import { Loader } from "src/components/Parts/loaderParts";
import { BaibaisIndexView } from "../Views/baibaisIndexView";
import { BaibaisSearchView } from "../Views/baibaisSearchView";
import { SearchConditionParts } from "../Parts/searchConditionParts";

export const BaibaisPage = () => {
    document.title = '新築・中古物件 | 有限会社東和ハウジング 宮崎県日向市'
    const [baibais, setBaibais] = useState(0)
    const [ onSearch, setOnSearch ] = useState(0)
    const [ searchQuery, setSearchQuery ] = useState('')

    const [searchArr, setSearchArr ] = useState({
        search: false,
        layouts:[],
        upperPrice:'',
        lowerPrice:'',
        cities:[],
        areas:[],
        schools:[]
    })

    useEffect(() => {
        setBaibais(new Useable(fetchBaibaiIndex(searchQuery)))
    },[searchQuery])

    const searchSubmit = (requestQuery) => {
        window.scrollTo(0,0)
        setOnSearch(0)
        setSearchQuery(requestQuery) 
    }

    return (
        <>
        {
        onSearch === 0 &&
        <section className="bukken">
            <div className="bukken-inner">
                <div className="pagetitle-chintai"><h1><span>売買物件</span></h1></div>
                <button className="btn" onClick={() => { setOnSearch(1) }}>物件・条件検索</button>
                <SearchConditionParts {...{searchArr}}></SearchConditionParts>

                {baibais && 
                <Suspense fallback={<Loader></Loader>}>
                    <BaibaisIndexView {...{data:baibais}}></BaibaisIndexView>
                </Suspense>
                }
            </div>
        </section>
        }
        {
            onSearch === 1 &&
            <BaibaisSearchView {...{
                setOnSearch, 
                searchSubmit, 
                setSearchArr,
                searchArr}}></BaibaisSearchView>
        }
        </>
    )
}
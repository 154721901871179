import React, { useState, Suspense } from "react";
import { Link } from "react-router-dom";
import { fetchContact, fetchBukken } from 'src/data/fetchContact';
import { Useable } from 'src/utils/Useable';
import { useForm } from 'react-hook-form'

const CompSend = (props) => {

   // console.log( props.data.getOrThrow())
    return (
        <>
             <div className="contact-comp-msg">
                <p>お問い合わせいただきありがとうございます。<br/>
                ご入力いただいた内容を受け付けました。<br/>
                後日、担当者よりご連絡差し上げますので、今しばらくお待ちください。<br/>
                </p>
                <Link to={`/`} className="btn">TOPヘ戻る</Link>
            </div>
        </>
    )
}

export const ConfirmView = (props) => {

    const inputs =  props.input;
    const [onSend, setOnSend] = useState(0);
    const formData = new FormData()

    const { handleSubmit, register } = useForm();

    const onSubmit = (currentInput) => {
       // console.log(currentInput)
        if(props.type === 'contact'){
            formData.append('name',currentInput.name)
            formData.append('address',currentInput.address)
            formData.append('name',currentInput.name)
            formData.append('tel',currentInput.tel)
            formData.append('email',currentInput.email)
            formData.append('message',currentInput.message)
            setOnSend(new Useable(
                fetchContact(formData)
            ))
        }else if(props.type === 'bukken'){
                formData.append('name',currentInput.name)
                formData.append('bukken',currentInput.bukken)
                formData.append('bukkenAddress',currentInput.bukkenAddress)
                formData.append('bukkenUrl',currentInput.bukkenUrl)
                formData.append('address',currentInput.address)
                formData.append('category',currentInput.category)
                formData.append('tel',currentInput.tel)
                formData.append('email',currentInput.email)
                formData.append('message',currentInput.message)
                setOnSend(new Useable(
                    fetchBukken(formData)
                ))
        }
    }
   // console.log(onSend)

    return (
        <>
        {onSend === 0 &&
        <>
            <form className="form" id="f-form" onSubmit={handleSubmit(onSubmit)}>
                <dl className="contact-form">
                    {
                        inputs.map((p,i) => {
                            return(
                                <>
                                    <dt><label>{p.label}</label></dt>
                                    <dd> 
                                        {p.val}
                                        <input type="hidden" {...register(p.name)} value={p.val}/>
                                    </dd>
                                </>
                            )
                        })
                    }
                </dl>
                <button type="submit">送信する</button>
            </form>
            <a onClick={() => {props.setOnConfrim(0)}} className="btn"> ＜ 戻る</a>
        </>
        }
        {onSend !== 0 &&
            <Suspense>
                <CompSend {...{data:onSend}}></CompSend>
            </Suspense>
        }
        </>
    )
}
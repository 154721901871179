import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchChintaiIndex } from "src/data/fetchChintai";
import { numFormat } from 'src/utils/textUtils';
import { Link } from "react-router-dom";

const BukkenList = (props) => {
    const {respons, data:chintais } = props.data.getOrThrow();
    
    let newChintais;
    respons === 'success' ? newChintais = chintais.slice(0, 4) :  newChintais = []


    return (
        <>
            {
                newChintais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                        <li key={i}>
                            <Link to={`/chintaisDetail/${c.id}`}>
                                <div className="topicks-item-img">
                                    <img src={src}/>
                                </div> 
                                <div className="topicks-item-info">
                                    <p>{c.name}</p>
                                    <p>家賃：{numFormat(c.price)}円</p>
                                    <p>住所：{c.address}</p>
                                </div> 
                            </Link>
                        </li>
                    )
                })
            }
        </>
    )
}

export const NewChintaisView = () => {

    const [chintais, setChintais] = useState(0)

    useEffect(() => {
        setChintais(new Useable(fetchChintaiIndex('')))
    },[])
    
    return (
        <>
         <ul className="topicks-list">
            { chintais !== 0 &&
            <Suspense>
                <BukkenList {...{data:chintais}}></BukkenList>
            </Suspense>
            }
        </ul>
        </>
    )
}
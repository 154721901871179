import React from "react";
import { Link } from "react-router-dom";
import { numFormat } from 'src/utils/textUtils';


export const ChintaisIndexView = (props) => {
    const {respons, data:chintais } = props.data.getOrThrow();
    

    return (
        <>
            <ul className="bukken-items-chintai">
            {
                chintais.map((c, i) => {
                    
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`

                    return (
                        <li key={i}>
                                <Link to={`/chintaisDetail/${c.id}`} target="_blank">
                                <div className="osusume-item-img">
                                    <figure><img src={src}/></figure>
                                    <p className="layout">{c.search_params.layout}</p>
                                    <p className="price">家賃 <strong>{numFormat(c.price)}円</strong></p>
                                </div>
                                <div className="bukken-item-info">
                                    <p className="bukken-item-area">
                                        <span>{c.search_params.category}</span>
                                        {c.search_params.city}/{c.search_params.area}
                                    </p>
                                    <h3 className="bukken-item-title">{c.name}</h3>
                                </div>
                                </Link>
                        </li>
                    )
                })
            }
            </ul>
        </>
    )
}
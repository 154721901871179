import React,{useState} from "react";
import { ChintaiColumnParts } from "../Parts/chintaiColumnsParts";
import { SlideGallaryParts } from "../Parts/slideGallary/slideGallaryParts";
import parse from 'html-react-parser';
import { textToBr, numFormat } from 'src/utils/textUtils';
import { formattedYearMonth } from 'src/utils/dateTime';
import { Link } from "react-router-dom";
import { BukkenFormView } from "./form/bukkenFormView";
import { ConfirmView } from "./form/confirmView";

export const ChintaisDetailView = (props) => {
    
    const {respons, data: detail} = props.data.getOrThrow()
    
    document.title = `${detail.name} - ${detail.address} | 賃貸物件  | 有限会社東和ハウジング 宮崎県日向市`

    
    const [input, setInput] = useState(0)
    const [onConfrim, setOnConfrim] = useState(0)

    return (
        <>
       
        <div className="breadcrumb-inner">    
            <nav className="breadcrumb">
                <ul>
                    <li><Link to={'/'}>Home</Link></li>
                    <li><Link to={'/chintais'}>賃貸</Link></li>
                    <li>{detail.name}</li>
                </ul>
            </nav>
        </div>
        {onConfrim === 0 &&
        <>
            <section className="bukkenview-chintai">
                <div className="bukkenview-inner">
                    <h1 className="bukkenview-title">{detail.name}</h1>
                    
                    { detail.docs &&  detail.docs.map((doc,i) => {
                        return (
                            <a href={`${process.env.REACT_APP_SYSDIR}/${doc.dir}/${doc.name}`} 
                                className="btn btn-small" 
                                target="_blank">
                                    物件概要書（PDF）
                            </a>
                        )
                    })
                  
                    }
                    <div className="bukkenview-topics">
                        <div className="item-layout">
                            <dl>
                                <dt>間取り</dt>
                                <dd>{detail.search_params.layout}</dd>
                            </dl>
                        </div>
                        <div className="item-price">
                            <h4></h4>
                            <dl>
                                <dt>家賃（管理費等）</dt>
                                <dd>{numFormat(detail.price)}円 <span>({detail.management_expenses})</span></dd>
                            </dl>
                        </div>
                        <div className="item-other">
                            <dl>
                                <dt>敷金</dt>
                                <dd>{detail.security_deposit}</dd>
                                <dt>礼金</dt>
                                <dd>{detail.key_money}</dd>
                            </dl>
                            <dl>
                                <dt>所在地</dt>
                                <dd>{detail.address}</dd>
                            </dl>
                            <dl>
                                <dt>築年月</dt>
                                <dd>{formattedYearMonth(detail.built_year_month)}</dd>
                            </dl>
                        </div>
                    </div>

                    <div className="bukkenview-images">
                        { detail.madoris.length > 0 &&
                        <div className="item-madori">
                            <SlideGallaryParts {...{images: detail.madoris}}></SlideGallaryParts>
                        </div>
                        }
                        { detail.images.length > 0 &&
                        <div className="item-photos">
                            <SlideGallaryParts {...{images: detail.images}}></SlideGallaryParts>
                        </div>
                        }
                    </div>
                    

                    <h3 className="osusume-point">物件概要</h3>
                    <p className="osusume-summary">
                        {parse(textToBr(detail.summary))}
                    </p>

                    {detail.gmap && 
                        <>
                        <h3 className="osusume-point">所在地地図</h3>
                        <div className="gmap">
                            {parse(detail.gmap)}
                        </div>
                        </>
                    }

                    <div className="bukkenview-info">
                        <ChintaiColumnParts {...{detail}}></ChintaiColumnParts>
                    </div>

                </div> 
                    
            </section>
            <BukkenFormView {...{setOnConfrim, 
                setInput, 
                dir:'chintaisDetail', 
                address:detail.address,
                id:detail.id, 
                name:detail.name}}>    
            </BukkenFormView>
        </>
        }
        
        {onConfrim === 1 &&
         <section className="contact">
         <div className="contact-inner">
             <h1 className="titleBar01">お問い合せフォーム</h1>
            <ConfirmView {...{input, setOnConfrim, type:'bukken'}}></ConfirmView>
        </div>
        </section>
        }
        
        </>
    )
}
import axios from 'axios'

export const fetchBaibaiIndex = async (query) => {
    
    try {
        const res = await axios.get(`${process.env.REACT_APP_API}/baibais/search.json?${query}`);
        return res.data;

    } catch (e) {
      if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
        console.log('400 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
        console.log('500 Error!!');
        console.log(e.message); //Axiosの例外オブジェクトとして扱える
      }
    }
};

export const fetchBaibaiInfo =  async (cId:string) => {

  try {
      const res = await axios.get(`${process.env.REACT_APP_API}/baibais/get.json?id=${cId}`);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }

}


export const fetchBaibaiRecoms = async () => {
    
  try {
      const res = await axios.get(`${process.env.REACT_APP_API}/baibais/recoms.json`);
      return res.data;

  } catch (e) {
    if (axios.isAxiosError(e) && e.response &&  e.response.status === 400) {
      console.log('400 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }else if(axios.isAxiosError(e) && e.response &&  e.response.status === 500){
      console.log('500 Error!!');
      console.log(e.message); //Axiosの例外オブジェクトとして扱える
    }
  }
};
import React from "react";
import { useForm } from 'react-hook-form';


export const BukkenFormView = (props) => {
    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = (currentInput) => {
        props.setInput(
            [   
                {name:'bukken', label:'お問い合わせ物件', val:props.name},
                {name:'bukkenAddress', label:'物件住所', val:props.address},
                {name:'bukkenUrl', label:'お問い合わせ物件URL', val: `${process.env.REACT_APP_URL}/${props.dir}/${props.id}`},
                {name:'category',label:'お問い合わせ種別', val:currentInput.category},
                {name:'name',label:'お名前', val:currentInput.name},
                {name:'address', label:'住所', val:currentInput.address},
                {name:'tel',label:'電話番号', val:currentInput.tel},
                {name:'email',label:'メールアドレス', val:currentInput.email},
                {name:'message',label:'お問合せ内容', val:currentInput.message},
            ]
        )
        props.setOnConfrim(1)
    }

    return (
        <section className="bukkenform">
            <div className="bukkenform-inner">
                <h1 className="bukkenform-title">お問合せフォーム</h1>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <dl>
                        <dt><label>お問合せ種別</label></dt>
                        <dd>
                            <div className="selectWrap">
                            <select {...register("category",{ required:true })}>
                                <option value="お問い合わせ">お問い合わせ</option>
                                <option value="見学希望">見学希望</option>
                                <option value="来店希望">来店希望</option>
                                <option value="その他">その他</option>
                            </select>
                            </div>
                        </dd>
                    </dl>
                    <dl>
                        <dt><label>お名前</label></dt>
                        <dd>
                            <input type="text" name="name" {...register("name",{ required:true })}/>
                            <span id="name_err" className={errors.name && "err_on"}>{errors.name && "エラー：未入力"}</span>

                        </dd>
                    </dl>
                    <dl>
                        <dt><label>現住所</label></dt>
                        <dd>
                            <input type="text"  name="address"　{...register("address",{ required:true })} />
                            <span id="name_err" className={errors.address && "err_on"}>{errors.address && "エラー：未入力"}</span>
                        </dd>
                    </dl>
                    <dl>
                        <dt><label>電話番号</label><span className="required">必須</span></dt>
                        <dd>
                            <input type="text" id="f-tel" className="f-size-5" {...register("tel",{ required:true })}/>
                            <span id="tel_err" className={errors.tel && "err_on"}>{errors.tel && "エラー：未入力"}</span>    
                        </dd>
                    </dl>
                    <dl>
                        <dt><label>メールアドレス</label></dt>
                        <dd>
                        <input type="email" id="f-email" {...register("email",{ required:true, pattern:/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/ })}/>
                            <span id="email_err" className={errors.email && "err_on"}>
                            {errors.email?.type === "required" && "エラー：未入力"}
                            {errors.email?.type === "pattern" && "エラー：形式が違います"}
                        </span>
                        </dd>
                    </dl>
                    <dl>
                        <dt><label>見学・来店<br className="pc767"/>希望日時<br/>その他</label></dt>
                        <dd>
                        <textarea {...register("message",{ required:true })}></textarea>
                        <span id="message_err" className={errors.message && "err_on"}>{errors.message && "エラー：未入力"}</span>
                        </dd>
                    </dl>
                    <p className="privacy">
                        <input type="checkbox" id="privacyCheck"  {...register("privacy",{ required:true })}/>
                        <label htmlFor="privacyCheck">プライバシーポリシー に同意する
                        </label>
                    </p>

                    <button type="submit">確認画面へ</button>
                </form>
            </div>
        </section>
        )

}


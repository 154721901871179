import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchBaibaiRecoms } from "src/data/fetchBaibai";
import { numFormat } from 'src/utils/textUtils';
import { Link } from "react-router-dom";

const BukkenList = (props) => {
    const {respons, data:baibais } = props.data.getOrThrow();
    
    return (
        <>
            {baibais && 
                baibais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                        <div className="osusume-item">
                            <Link to={`/baibaisDetail/${c.id}`}>
                                <div className="osusume-item-img">
                                    <figure><img src={src}/></figure>
                                    <p className="layout">{c.room_layout}</p>
                                    <p className="price">価格 <strong>{numFormat(c.price)}</strong></p>
                                </div>
                                <div className="osusume-item-info">
                                    <p className="bukken-item-area">
                                        <span>{c.search_params.category}</span>
                                        {c.search_params.city}/{c.search_params.area}
                                    </p>
                                    <h3 className="bukken-item-title">{c.name}</h3>
                                </div>
                            </Link>
                        </div>
                    )
                })
            }
        </>
    )
}

export const RecomsBaibaiView = () => {

    const [baibais, setbaibais] = useState(0)

    useEffect(() => {
        setbaibais(new Useable(fetchBaibaiRecoms()))
    },[])
    
    return (
        <>
         { baibais !== 0 &&
                <Suspense>
                    <BukkenList {...{data:baibais}}></BukkenList>
                </Suspense>
                }
        </>
    )
}
import React from "react";
import { Link } from "react-router-dom";


export const NoContents = () => {

    return(
        <>
            <div className="contact-comp-msg">
                <p>404:このURLは存在しません。<br/>
                </p>
                <Link to={`/`} className="btn">TOPヘ戻る</Link>
            </div>
        </>
    )
}
import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchLandIndex } from "src/data/fetchLand";
import { Loader } from "src/components/Parts/loaderParts";
import { LandsIndexView } from "../Views/landsIndexView";
import { LandsSearchView } from "../Views/landsSearchView";
import { SearchConditionParts } from "../Parts/searchConditionParts";

export const LandsPage = () => {
    document.title = '土地情報 | 有限会社東和ハウジング 宮崎県日向市'
    
    const [lands, setLands] = useState(0)
    const [ onSearch, setOnSearch ] = useState(0)
    const [ searchQuery, setSearchQuery ] = useState('')
    const [searchArr, setSearchArr ] = useState({
        search: false,
        upperPrice:'',
        lowerPrice:'',
        cities:[],
        areas:[],
        schools:[]
    })


    useEffect(() => {
        setLands(new Useable(fetchLandIndex(searchQuery)))
    },[searchQuery])

    const searchSubmit = (requestQuery) => {
        window.scrollTo(0,0)
        setOnSearch(0)
        setSearchQuery(requestQuery) 
    }


    return (
        <>
         {
        onSearch === 0 &&
         <section className="bukken">
            <div className="bukken-inner">
                <div className="pagetitle-land"><h1><span>土地</span></h1></div>
                <button className="btn" onClick={() => { setOnSearch(1) }}>物件・条件検索</button>
                <SearchConditionParts {...{searchArr}}></SearchConditionParts>

                {lands &&
                    <Suspense fallback={<Loader></Loader>}>
                        <LandsIndexView {...{data: lands}}></LandsIndexView>
                    </Suspense>
                }
            </div>
        </section>
        }
        {
            onSearch === 1 &&
            <LandsSearchView {...{
                setOnSearch, 
                searchSubmit, 
                setSearchArr,
                searchArr}}></LandsSearchView>
        }
        </>
    )
}
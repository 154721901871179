import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchLandRecoms } from "src/data/fetchLand";
import { numFormat } from 'src/utils/textUtils';
import { Link } from "react-router-dom";

const BukkenList = (props) => {
    const {respons, data:lands } = props.data.getOrThrow();
    
    return (
        <>
            {lands && 
                lands.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                        <div className="osusume-item">
                            <Link to={`/landsDetail/${c.id}`}>
                                <div className="osusume-item-img">
                                    <figure><img src={src} /></figure>
                                    <p className="layout">{c.tubo}</p>
                                    <p className="price">価格 <strong>{c.price}</strong></p>
                                </div>
                                <div className="osusume-item-info">
                                    <p className="osusume-item-area">
                                        <span>土地</span>
                                        {c.search_params.city} / {c.search_params.area}
                                    </p>
                                    <h3 className="osusume-item-title">{c.address}</h3>
                                </div>
                            </Link>
                        </div>
                    )
                })
            }
        </>
    )
}

export const RecomsLandView = () => {

    const [lands, setlands] = useState(0)

    useEffect(() => {
        setlands(new Useable(fetchLandRecoms()))
    },[])
    
    return (
        <>
          { lands !== 0 &&
                <Suspense>
                    <BukkenList {...{data:lands}}></BukkenList>
                </Suspense>
                }
        </>
    )
}
import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchChintaiRecoms } from "src/data/fetchChintai";
import { numFormat } from 'src/utils/textUtils';
import { Link } from "react-router-dom";

const BukkenList = (props) => {
    const {respons, data:chintais } = props.data.getOrThrow();
    
    return (
        <>
            {chintais && 
                chintais.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                    <div className="osusume-item">
                        <Link to={`/chintaisDetail/${c.id}`}>
                            <div className="osusume-item-img">
                                <figure><img src={src}/></figure>
                                <p className="layout">{c.search_params.layout}</p>
                                <p className="price">家賃 <strong>{numFormat(c.price)}円</strong></p>
                            </div>
                            <div className="osusume-item-info">
                                <p className="bukken-item-area">
                                    <span>{c.search_params.category}</span>
                                    {c.search_params.city}/{c.search_params.area}
                                </p>
                                <h3 className="bukken-item-title">{c.name}</h3>
                            </div>
                        </Link>
                    </div>
                    )
                })
            }
        </>
    )
}

export const RecomsChintaiView = () => {

    const [chintais, setChintais] = useState(0)

    useEffect(() => {
        setChintais(new Useable(fetchChintaiRecoms()))
    },[])
    
    return (
        <>
          { chintais !== 0 &&
                <Suspense>
                    <BukkenList {...{data:chintais}}></BukkenList>
                </Suspense>
                }
        </>
    )
}
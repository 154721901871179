import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { ConfirmView } from "../Views/form/confirmView";


export const ContacPage = () => {
    document.title = 'お問合せ | 有限会社東和ハウジング 宮崎県日向市'
    const type = 'contact'

    const { register, formState: { errors }, handleSubmit, setError, clearErrors, getValues } = useForm();
    
    const [input, setInput] = useState(0)
    const [onConfrim, setOnConfrim] = useState(0)

    const onSubmit = (currentInput) => {
        setInput(
            [
                {name:'name',label:'お名前', val:currentInput.name},
                {name:'address', label:'住所', val:currentInput.address},
                {name:'tel',label:'電話番号', val:currentInput.tel},
                {name:'email',label:'メールアドレス', val:currentInput.email},
                {name:'message',label:'お問合せ内容', val:currentInput.message},
            ]
        )
        setOnConfrim(1)
    }
    return (
        <section className="contact">
        <div className="contact-inner">
            <h1 className="titleBar01">お問い合せフォーム</h1>
            {onConfrim === 0 &&
            <form className="form" id="f-form" onSubmit={handleSubmit(onSubmit)}>
                <dl className="contact-form">
                    <dt><label>お名前</label><span className="required">必須</span></dt>
                    <dd> 
                        <input type="text" className="f-size-5" {...register("name",{ required:true })}/>
                        <span id="name_err" className={errors.name && "err_on"}>{errors.name && "エラー：未入力"}</span>
                    </dd>
                    <dt><label>住所</label><span className="required">必須</span></dt>
                    <dd>  
                        <input type="text" id="address"  className="f-size-10" {...register("address",{ required:true })}/>
                        <span id="name_err" className={errors.address && "err_on"}>{errors.address && "エラー：未入力"}</span>
                    </dd>
                    <dt><label>電話番号</label><span className="required">必須</span></dt>
                    <dd>
                         <input type="text" id="f-tel" className="f-size-5" {...register("tel",{ required:true })}/>
                         <span id="tel_err" className={errors.tel && "err_on"}>{errors.tel && "エラー：未入力"}</span>    
                    </dd>
                    <dt><label>メールアドレス</label><span className="required">必須</span></dt>
                    <dd> 
                        <input type="email" id="f-email" className="f-size-10" {...register("email",{ required:true, pattern:/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/ })}/>
                        <span id="email_err" className={errors.email && "err_on"}>
                            {errors.email?.type === "required" && "エラー：未入力"}
                            {errors.email?.type === "pattern" && "エラー：形式が違います"}
                        </span>
                    </dd>
                    <dt><label>お問合せ内容</label><span className="required">必須</span></dt>
                    <dd>
                        <textarea name="message" id="f-message"  {...register("message",{ required:true })}></textarea>
                        <span id="message_err" className={errors.message && "err_on"}>{errors.message && "エラー：未入力"}</span>
                    </dd>
                </dl>
                <p className="privacy">
                    <input type="checkbox" id="privacyCheck" {...register("privacy",{ required:true })}/>
                    <label htmlFor="privacyCheck">プライバシーポリシー に同意する
                    </label>
                   
                </p>
                <span id="message_err">{errors.message && "エラー：未入力"}</span>
                <button type="submit">確認画面へ</button>
            </form>    
            }       
             {onConfrim !== 0&&
                <ConfirmView {...{input, setOnConfrim, type}}></ConfirmView>
             }
        </div>
    </section>
    )
}
import React,{useState} from "react";
import { NewChintaisView } from "../Views/index/newChintaisView";
import { NewBaibaisView } from "../Views/index/newBaibaisView";
import { NewLandsView } from "../Views/index/newLandsView";
import { RecomsChintaiView } from "../Views/index/recomsChintaiVeiw";
import { RecomsLandView } from "../Views/index/recomsLandVeiw";
import { RecomsBaibaiView } from "../Views/index/recomsBaibaiVeiw";

export const IndexPage = () => {
    document.title = '有限会社東和ハウジング 宮崎県日向市'
    document.body.id = "";
    const [activeTab, setActiveTab] = useState(0);

    const tabActive = (index) => {
        const osusumeTabLiArr = Array.from(document.querySelectorAll('.osusume-tab li'));
        const osusumeItemWrapArr = Array.from(document.querySelectorAll('.osusume-item-wrap'));

        // 前にクリックされたタブの active クラスを削除する
        osusumeTabLiArr[activeTab].classList.remove('active');

        // 選択されたタブに active クラスを追加する
        osusumeTabLiArr[index].classList.add('active');

        // 選択されたタブに対応するコンテンツに active クラスを追加する
        osusumeItemWrapArr.forEach((o, i) => {
        if (i === index) {
            o.classList.add('active');
        } else {
            o.classList.remove('active');
        }
        });

        // 選択されたタブのインデックスを更新する
        setActiveTab(index);
    };

    return (
        <>
        <section className="main">
        <div className="main-inner">

            <div className="slider-wrap">
                <img src="./images/main03.jpg"/>
                <div className="main-info">
                    <p className="main-info-time">
                        AM８時３０分～PM６時３０<br/>
                        定休日：年末、年始のみ
                    </p>
                    <p className="main-info-tel">
                        <span>TEL </span>
                        0982-54-0606
                        <hr/>
                        <span>Fax </span>
                        0982-54-0660
                    </p>
                    
                </div>
                <div className="main-news">
                    <h3 className="main-new-title">お知らせ</h3>
                    <a href="https://blog.goo.ne.jp/towahau/e/afb5a9035ec212ead0c33507b9aa3481"><span className="date">2023.4.14</span> 財光寺桃ノ木貸家Ａ号～３DK（南向きで日当たり良好）</a>
                </div>
            </div>
        </div>
    </section>

    <section className="osusume">
        <div className="inner">
            <div className="osusume-title-bar"><h1><span>おすすめ物件</span></h1></div>
            <div className="osusume-bukken">
                <ul className="osusume-tab">
                    <li className="active" onClick={() => tabActive(0)}><span>賃貸</span></li>
                    <li onClick={() => tabActive(1)}><span>土地</span></li>
                    <li onClick={() => tabActive(2)}><span>新築・中古</span></li>
                </ul>
                <div className="osusume-contetns">
                    <div className="osusume-item-wrap active">
                       <RecomsChintaiView></RecomsChintaiView>
                    </div>
                    <div className="osusume-item-wrap">
                        <RecomsLandView></RecomsLandView>
                    </div>
                    <div className="osusume-item-wrap">
                        <RecomsBaibaiView></RecomsBaibaiView>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="topicks">
        <div className="inner">
            <h1 className="topicks-title">NEW<span>新着物件</span></h1>
            <div className="topicks-items-wrap">
                <div className="topicks-item">
                    <h2 className="topicks-item-title">賃貸</h2>
                   <NewChintaisView></NewChintaisView>
                </div>

                <div className="topicks-item">
                    <h2 className="topicks-item-title">土地</h2>
                    <ul className="topicks-list">
                        <NewLandsView></NewLandsView>
                    </ul>
                </div>

                <div className="topicks-item">
                    <h2 className="topicks-item-title">新築・中古</h2>
                    <ul className="topicks-list">
                        <NewBaibaisView></NewBaibaisView>
                    </ul>
                </div>
                
            </div>
        </div>
    </section>
        </>
    )
}
import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchLandInfo } from "src/data/fetchLand";
import { Loader } from "src/components/Parts/loaderParts";
import { LandsDetailView } from "../Views/landsDetailView";
import { useParams } from 'react-router-dom';

export const LandsDetailPage = () => {
    document.body.id = "contents";
    const { id } = useParams()

    const [detail, setDetail] = useState(0)


    useEffect(() => {
        setDetail(new Useable(fetchLandInfo(id)))
    },[])

    return (
        <>
        {detail && 
        <Suspense fallback={<Loader></Loader>}>
            <LandsDetailView {...{data:detail}}></LandsDetailView>
        </Suspense>
        }
        </>
    )
}
import React from "react";
import { Link } from "react-router-dom";

export const TopTemplate = ({children}) => {
    
    
    let headerNavbtnOn = true
   

    const headerNavClick = () => {
        const headerNavBtn =  document.getElementsByClassName('header-nav-btn');
        const headerNav =  document.getElementsByClassName('header-nav');
        headerNavbtnOn ? headerNavBtn[0].classList.add("active") :  headerNavBtn[0].classList.remove("active");
        headerNavbtnOn ? headerNav[0].classList.add("active") :  headerNav[0].classList.remove("active");
        headerNavbtnOn = !headerNavbtnOn;
    }
   
    return (
        <>  
            <header>
                <div className="header-inner">
                    <h1 className="header-logo"><Link to={`/`}><img src={`${process.env.REACT_APP_DIR}images/logo.png`}/></Link></h1>
                    <nav className="header-nav">
                        <ul>
                            <li><Link to={`/`}>TOP</Link></li>
                            <li><Link to={`/chintais`}>賃貸を探す</Link></li>
                            <li><Link to={`/baibais`}>新築・中古物件を探す</Link></li>
                            <li><Link to={`/lands`}>土地を探す</Link></li>
                            <li><Link to={`/company`}>会社概要</Link></li>
                            <li><Link to={`/contact`}>お問い合せ</Link></li>
                        </ul>
                    </nav>
                    <div className="header-nav-btn" onClick={() => {headerNavClick()}}><p>menu</p><span></span><span></span><span></span></div>

                </div>
            </header>
            {children}
        </>
    )

}
import React,{memo} from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./image-gallary-custom.css";

const ThumbnailItem = memo(({ thumbnail }) => {
    return (
      <div style={{ margin: "0 5px" }}>
        <img src={thumbnail} alt="" />
      </div>
    )
})

export const SlideGallaryParts = memo(({images}) => {
    
    const gallary = images.map((img, i) => {
                        return {
                            original:`${process.env.REACT_APP_SYSDIR}/${img.dir}/${img.name}`,
                            thumbnail:`${process.env.REACT_APP_SYSDIR}/${img.dir}/${img.name}`
                        }
                    })
                    
   // console.log(gallary)

    return (
        <>
             <ImageGallery
                items={gallary}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={false}
                showThumbnails
                thumbnailPosition="bottom"
                renderThumbInner={(item) => <ThumbnailItem thumbnail={item.thumbnail} />}
            ></ImageGallery>
        </>
    )

})

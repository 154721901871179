import React, { Suspense, useEffect, useState } from "react";
import { Useable } from 'src/utils/Useable';
import { fetchLandIndex } from "src/data/fetchLand";
import { Link } from "react-router-dom";
const BukkenList = (props) => {
    const {respons, data:lands } = props.data.getOrThrow();

    let newLands;
    respons === 'success' ? newLands = lands.slice(0, 4) :  newLands = []
    
    return (
        <>
            {
                newLands.map((c, i) => {
                    let src;
                    c.images[0] ? src = `${process.env.REACT_APP_SYSDIR}/${c.images[0].dir}/${c.images[0].name}` 
                    : src = `${process.env.REACT_APP_DIR}/images/noimg.png`
                    return (
                    <li key={i}>
                        <Link to={`/landsDetail/${c.id}`}>
                            <div className="topicks-item-img">
                                <img src={src}/>
                            </div> 
                            <div className="topicks-item-info">
                                <p>{c.name}</p>
                                <p>価格：{c.price}</p>
                                <p>住所：{c.address}</p>
                            </div> 
                        </Link>
                    </li>
                    )
                })
            }
        </>
    )
}

export const NewLandsView = () => {

    const [lands, setlands] = useState(0)

    useEffect(() => {
        setlands(new Useable(fetchLandIndex()))
    },[])
    
    return (
        <>
         <ul className="topicks-list">
            { lands !== 0 &&
            <Suspense>
                <BukkenList {...{data:lands}}></BukkenList>
            </Suspense>
            }
        </ul>
        </>
    )
}
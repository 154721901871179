import React, { Suspense, useEffect, useState, memo } from "react";
import { Useable } from 'src/utils/Useable';
import { Loader } from "src/components/Parts/loaderParts";
import { fetchLayouts, fetchAreas, fetchCities, fetchSchools } from '../../../data/fetchSearchParam'
import { CheckboxFormParts } from "../../Parts/searchForm/checkboxFormParts";
import { useForm } from 'react-hook-form';


export const LayoutCheckView = memo((props) => {

    const [ layouts, setLayouts ] = useState(0) 
    const [ checkLayouts, setCheckLayouts ] =  useState(props.checkLayouts) 

    useEffect(() => {
        setLayouts(new Useable(fetchLayouts(`type=${props.type}`)))
    },[])

    useEffect(() => {
        props.setCheckLayouts(checkLayouts)
    },[checkLayouts])

    return (
        <dl className="searchFrom-madori">
            <dt>間取り</dt>
            <dd>
                {layouts !== 0 && 
                <Suspense fallback={<Loader></Loader>}>
                    <CheckboxFormParts {...{
                            type:'layout',
                            data:layouts, 
                            checkValues: checkLayouts, 
                            setCheckValues: setCheckLayouts}}>
                    </CheckboxFormParts>
                </Suspense>
                }
            </dd>
        </dl>
    )
})

export const PriceInputView = memo(({register, unit}) => {
    
    return (
        <dl className="searchFrom-price">
            <dt>{unit === '万円' ? '価格' : '家賃'}</dt>
            <dd>
                <div className="searchFrom-price-input">
                    <div>
                        <span>下限</span>
                        <input type="number" name="underPrice" className="input-price" {...register("underPrice")}/> {unit}
                    </div>
                    <div>
                        <span>上限</span>
                        <input type="number" name="upperPrice" className="input-price" {...register("upperPrice")}/> {unit}
                    </div>
                </div>
            </dd>
        </dl>
    )
})



export const CitiesCheckView = memo((props) => {

    const [ cities, setCities ] = useState(0)
    const [ checkCities, setCheckCities ] = useState(props.checkCities)

    useEffect(() => {
        setCities(new Useable(fetchCities(`type=${props.type}`)))
    },[])

    useEffect(() => {
        props.setCheckCities(checkCities)
    },[checkCities])

    return (
        <dl className="searchFrom-madori">
            <dt>市町村</dt>
            <dd>
                {cities !== 0 && 
                <Suspense fallback={<Loader></Loader>}>
                    <CheckboxFormParts {...{
                        type:'city',
                        data:cities, 
                        checkValues: checkCities, 
                        setCheckValues: setCheckCities}}>    
                    </CheckboxFormParts>
                </Suspense>
                }
            </dd>
        </dl>
    )
})

export const AreasCheckView = memo((props) => {
    
    const [ areas, setAreas ] = useState(0)
    const [ checkAreas, setCheckAreas ] = useState(props.checkAreas)

    useEffect(() => {
        const cityQuery = `type=${props.type}&cities=${props.checkCities.join(',')}`
        setAreas(new Useable(fetchAreas(encodeURI(cityQuery))))

    },[props.checkCities])

    useEffect(() => {
        props.setCheckAreas(checkAreas)
    },[checkAreas])

    return (
        <dl className="searchFrom-madori">
        <dt>地区</dt>
        <dd>
            {areas !== 0 && 
            <Suspense fallback={<Loader></Loader>}>
                <CheckboxFormParts {...{
                    type:'area',
                    data:areas, 
                    checkValues: checkAreas, 
                    setCheckValues: setCheckAreas}}>    
                </CheckboxFormParts>
            </Suspense>
            }
        </dd>
    </dl>
    )

})

export const SchoolsCheckView = memo((props) => {
    
    const [ schools, setSchools ] = useState(0)
    const [ checkSchools, setCheckSchools ] = useState(props.checkSchools)
    

    useEffect(() => {
        
        const cityQuery = `cities=${props.checkCities.join(',')}`
        const areaQuery = `areas=${props.checkAreas.join(',')}`

        setSchools(new Useable(
            fetchSchools(encodeURI(`type=${props.type}&${cityQuery}&${areaQuery}`))))

    },[props.checkCities, props.checkAreas])

    useEffect(() => {
        props.setCheckSchools(checkSchools)
    },[checkSchools])

    return (
        <dl className="searchFrom-madori">
            <dt>小学校</dt>
            <dd>
                {schools !== 0 && 
                <Suspense fallback={<Loader></Loader>}>
                    <CheckboxFormParts {...{
                        type:'schools',
                        data:schools, 
                        checkValues: checkSchools, 
                        setCheckValues: setCheckSchools}}>    
                    </CheckboxFormParts>
                </Suspense>
                }
            </dd>
        </dl>
    )

}) 


import React,{useState, memo} from "react";


export const CheckboxFormParts = memo((props) => {

    const {respons, data:layouts } = props.data.getOrThrow();
    const handleCheck = checkedValue => {         
       
        const newLayouts = props.checkValues.includes(checkedValue)
          ? props.checkValues.filter(id => id !== checkedValue)
          : [...(props.checkValues ?? []), checkedValue];

          props.setCheckValues(newLayouts);
    };
    return (
        <>
            {
                respons === 'success' && 
                <ul className="searchFrom-madori-list">
                    {
                        layouts.map((layout,i) => {
                            const check = props.checkValues.filter(n => n === layout.name );
                            const layoutProps= {
                                type: props.type,
                                id:i,
                                name:layout.name, 
                                count:layout.count,
                                check:check[0] ? true : false 
                            };
                            return (
                               <CheckBox
                                {...layoutProps}  
                                onChange={() => handleCheck(layout.name)}
                                key={i} 
                               >
                               </CheckBox>
                            )
                        })
                    }
                </ul>
            }
        </>
    )

})

const CheckBox = memo((props) => {
    
    const [ checkValue, setCheckValue] = useState(props.check);

    return (<>
        <li className="col3">
            <div className="f-checkbox">
                <input type="checkbox" id={`check-${props.type}-${props.id}`}
                    name="license" 
                    checked={checkValue} 
                    onChange={() => {
                        checkValue ? setCheckValue(false) : setCheckValue(true);
                        props.onChange();
                    }} 
                />
                <label htmlFor={`check-${props.type}-${props.id}`} >{props.name}（{props.count}）</label>
            </div>
        </li>
    </>);
})